import {Injectable} from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import {Observable} from 'rxjs';
import {Settings as luxonSettings} from 'luxon';
import {ReportOrView} from 'src/shared/models/Report';

@Injectable()

export class ReportV2Service {


  private uploadHost = '_MICRO_SERVICE_UPLOAD_';
  private reportService = "_MICRO_SERVICE_/reports"

  private dashboardUrl = `_MICRO_SERVICE_/metabase/metabase-embed-url?dashboard=`;


  constructor(private http: HttpClient) {


  }

  headers: any = new HttpHeaders().set('Content-Type', 'application/json; charset=utf-8');


  printServiceSearch(columns:any, queryParams: any = {}, searchType: string): Observable<any> {
    const user_date= new Date();
    const user_timezone = luxonSettings.defaultZoneName;
    return this.http.post(`/{tenant}/reports/print?${queryParams}`, {columns, user_date})
    .map((response: any) => {
      return response;
    });
  }

  // printServiceSearch(columns:any, queryParams: any = {}, searchType: string, results: Array<any>, tenant_id: string): Observable<any> {
  //   const user_date= new Date();
  //   const user_timezone = luxonSettings.defaultZoneName;
  //   return this.http.post(`${this.uploadHost}/report/print`, {columns, user_date, results, queryParams, searchType, user_timezone, tenant_id})
  //   .map((response: any) => {
  //     return response;
  //   });
  // }

  createReport(report: any): Observable<any> {
    return this.http
      .post(`${this.uploadHost}/create`, report)
      .map((response: any) => response.data);
  }

  getDownloadStatus(tenantId: string): Observable<any> {
    return this.http
      .get(`${this.uploadHost}/service/download?tenantId=${tenantId}`)
      .map((response: any) => {
        return response;
      });
  }

  servicePrint(filters:any, queryParams: any = {}, columns:any =[], readableFilters:any =[]): Observable<any> {
    const user_date = new Date();
    return this.http
      .post(`${this.uploadHost}/report/print`, {
        filters,
        user_date,
        columns,
        readableFilters,
        queryParams
      })
      .map((response: any) => {
        return response;
      });
  }

  //service report create
  createReportOrView(reportOrView: any): Observable<any> {
    return this.http
      .post(`${this.uploadHost}/report/create`, { ...reportOrView })
      .map((response: any) => response);
  }

  getReportOrViewList(tenantId: string, action_type: string = 'SERVICE_REPORT'): Observable<any> {
    return this.http
      .get(
        `${this.uploadHost}/report/list?tenant_id=${tenantId}&action_type=${action_type}`
      )
      .map((response: any) => {
        return response;
      });
  }

  getReportById(reportId: string): Observable<any> {
    return this.http
      .get(`${this.uploadHost}/report/info?report_id=${reportId}`)
      .map((response: any) => {
        return response;
      });
  }

  // service report update
  updateReport(report: ReportOrView): Observable<any> {
    return this.http
      .put(`${this.uploadHost}/report/update`, { ...report })
      .map((response: any) => {
        return response;
      });
  }

  runReport(tenant_id: string, report_id: string, columns?:any ): Observable<any> {
    const user_date= new Date();
    const user_timezone = luxonSettings.defaultZoneName;
    const results= []

    const runReportBody= {
      tenant_id,
      report_id,
      user_date,
      user_timezone,
      results,
      columns
    }
    return this.http
      .post(`${this.uploadHost}/report/print`, runReportBody)
      .map((response: any) => {
        return response;
      });
  }

  removeReport(reportId: string): Observable<any> {
    return this.http
      .delete(`${this.uploadHost}/service/report?report_id=${reportId}`)
      .map((response: any) => {
        return response;
      });
  }

  getCostVarianceExport(tenantId: string): Observable<any> {
    const httpOptions = {
      responseType: 'blob' as 'json',
      observe: 'response'
    };

    return this.http
      .get(`${this.reportService}/get-cost-variance-export?tenantId=${tenantId}`, {observe: 'response', responseType: 'blob' as 'json'})
      .map((response: any) => {
        return response;
      });
  }


  getAlignMetaBaseResponse(configId: string, dashBoardId: string) {
    const headers = new HttpHeaders().set('Content-Type', 'application/json; charset=utf-8');
    return this.http
      .post(`${this.dashboardUrl}${dashBoardId}`, { 'params': [{ 'key': 'align_config_id', 'value': configId }] }, { headers, responseType: 'text' })
      .toPromise()
      .then((response: any) => response);
  }

  getMetabaseResponse(tenantId: string, dashBoardId: string) {
    const headers = new HttpHeaders().set('Content-Type', 'application/json; charset=utf-8');
    return this.http
      .post(`${this.dashboardUrl}${dashBoardId}`, { 'params': [{ 'key': 'tenant_id', 'value': tenantId }] }, { headers, responseType: 'text' })
      .toPromise()
      .then((response: any) => response);
  }

}
