import {CvlSettings, FrontEndSettings} from "../../libs/api/settings/settings.service";

export default class FrontEndSettingsUtil{

  static set(settings:FrontEndSettings):void {
    (<any>window).MISO_FRONTEND_SETTINGS = settings;
  };

  static setTenantConfig(tenantConfig:any):void {
    (<any>window).TENANT_FRONTEND_CONFIG = tenantConfig;
  };


  static setCvl(settings:CvlSettings):void {
    (<any>window).MISO_CVL_SETTINGS = settings;
  };

  static setDomain(domain:string):void {
    this.get().MISO3_TENANT_DOMAIN = domain;
  };

  static getDomain(): string {
    return this.get().MISO3_TENANT_DOMAIN;
  }

  static setTenantCode(domain:string):void {
    this.get().MISO3_TENANT_CODE = domain;
  };

  static getTenantCode(): string {
    return this.get().MISO3_TENANT_CODE;
  }

  static setCurrency(currency:any):void {
    this.get().MISO3_TENANT_CURRENCY = currency;
  };

  static getCurrencyCode():string {
    return this.get().MISO3_TENANT_CURRENCY?this.get().MISO3_TENANT_CURRENCY.code:'USD';
  };

  static get():FrontEndSettings {
    return (<any>window).MISO_FRONTEND_SETTINGS;
  }

  static getTenantConfig():any {
    return (<any>window).TENANT_FRONTEND_CONFIG;
  }

  static getCvl():CvlSettings {
    return (<any>window).MISO_CVL_SETTINGS;
  }

  static getStripeKey(): string {
    return (<any>window).STRIPE_PUBLISHABLE_KEY;
  }
}

