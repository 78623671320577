import dateUtil from './../../common/utils/DateUtil'

export default class FeDateUtil{

  static isLastDayOfMonth(day: Date): boolean {
    return dateUtil.isLastDayOfMonth(day);
  }

  static getEndDate(startDate: Date, termLength: number): Date {
    return dateUtil.getEndDate(startDate, termLength);
  }

  static daysDiff(date1: Date, date2:Date):number {
    return dateUtil.daysDiff(date1, date2);
  }

  static isBeforeToday(date: Date):boolean {
    return dateUtil.isBeforeToday(date);
  }

  static toFormat(date, format){
    return dateUtil.toFormat(date, format);
  }

  static secondsDiff(date1: Date | string, date2:Date | string):number {
    return dateUtil.secondsDiff(date1, date2);
  }

  static applyTimeZoneFromISO(time: Date | string, timezone:string = '') {
    return dateUtil.applyTimeZoneFromISO(time, timezone);
  }

  static calculateDaysOutstanding(date: string): number {
    const createdDate = new Date(date);
    const currentDate = new Date();
    const timeDifference = currentDate.getTime() - createdDate.getTime();
    const daysDifference = Math.floor(timeDifference / (1000 * 3600 * 24));
    return daysDifference;
  }

}
