<mi-page-header title="{{ 'TENANT.VENDOR_LIST_TITLE' | translate }}" [breadcrumbs]="breadCrumbs" [links]="headerLinks">
</mi-page-header>
<div>
    <section class="main-container" *ngIf="loaded; else loading">
        <div class="card p-3">
            <div>
                <h4>
                    Last Updated:
                    <b>
                        {{tenantReportDetails.reportDate | date: 'MM/dd/yyyy hh:mm:ss a' }}
                    </b>
                </h4>
                <hr>
                </div>
                <div class="flex-container">
                    <div class="box item1 ">
                    <mi-chart [tooltipSuffix]="'%'" title="Spend by Category" chartType="pie" [data]="PieData"></mi-chart>
                    </div>
                <div class="box item2" id="out-of-term-spend-box">
                    <mi-bar-line-chart [yAxesPrefix]="barLineChartYAxesPrefix" [tooltipPrefix]="barLineChartTooltipPrefix"
                        title="Spend to negotiate" chartType="line" [data]="monthlySpendChartData"></mi-bar-line-chart>
                </div>
                <div class="box item3">
                    <mi-chart [tooltipSuffix]="'%'" title="Spend by Term status" chartType="doughnut" [data]="termReviewData">
                    </mi-chart>
                    </div>
                    </div>
        </div>



        <div class="service-data-highlight-list card p-3 my-2">

<h4>
    <b>Data Analytics</b>
</h4>
            <div class="service-details">
                <ul class="dashboard-tools">
                    <li class="link" (click)="fixDataIntegrityHandler()">
                        <div class="title">
                            <span [ngClass]="getDataIntegrityGrade(tenantReportDetails.totalDataIntegrityCmrcPercentage).colorClass">
                                {{getDataIntegrityGrade(tenantReportDetails.totalDataIntegrityCmrcPercentage).grade}}
                            </span>
                        </div>
                        Data Integrity Score
                        <b>
                            {{getPercentage(tenantReportDetails.totalDataIntegrityCmrcPercentage)}}
                        </b>
                    </li>
                    <li class="link" routerLink="/vendors">
                        <div class="title">
                            {{tenantReportDetails.tenantVendorCount}}
                        </div>
                        {{pluralizeWord("Vendor", tenantReportDetails.tenantVendorCount)}}
                        <br>
                        Monitored
                    </li>
                    <li class="link" routerLink="/services">
                        <div class="title">
                            {{ tenantReportDetails.totalCmrc | round | tenantcurrency:
                            '0.0-0'}} mo.
                        </div>
                        Normalized
                        <br>
                        Spend
                    </li>

<li class="link" (click)="downloadCostVarianceExport('increase')">
                        <div *ngIf="!downloadCostVarianceStatusIncrease; else loading">
                            <div class="title">
                                {{tenantReportDetails.netCostChangeValues.costIncreasedValue | tenantcurrency: '0.0-0'}} mo.
                            </div>
                            {{getPercentage(tenantReportDetails.netCostChangeValues.costIncreasedPercentage)}} Cost Increased
                            <br>
                            Last 90 days
                        </div>
                    </li>

<li class="link" (click)="downloadCostVarianceExport('decrease')">
                        <div *ngIf="!downloadCostVarianceStatusDecrease; else loading">
                            <div class="title">
                                {{tenantReportDetails.netCostChangeValues.costDecreasedValue |
                                tenantcurrency:
                                '0.0-0'}} mo.
                            </div>
                            {{getPercentage(tenantReportDetails.netCostChangeValues.costDecreasedPercentage)}} Cost Decreased
                            Last
                            90 days
                        </div>
                    </li>


                </ul>
                </div>
                <div class="service-details">
                    <ul class="dashboard-tools">
                    <li>
                        <div class="title">{{largestInternalOwner.miMisoUserName}}</div>
                        Internal Owner {{getPercentage(largestInternalOwner.totalCmrcPercentage)}}
                        <br>
                        of Spend
                    </li>
                    <li>
                        <div class="title">
                            {{tenantReportDetails.comingOutOfTerm90DaysValues.comingOutOfTerm90DaysCmrc | tenantcurrency:
                            '0.0-0'}} mo.
                        </div>
                        {{getPercentage(tenantReportDetails.comingOutOfTerm90DaysValues.comingOutOfTerm90DaysCmrcPercentage)}}
                        Coming Out-of-term
                        <br>
                        Next 90 days
                    </li>


                    <li>
                        <div class="title">
                            {{tenantReportDetails.currentlyOutOfTermValues.currentlyOutOfTermCmrc |
                            tenantcurrency: '0.0-0'}} mo.
                        </div>
                        {{getPercentage(tenantReportDetails.currentlyOutOfTermValues.currentlyOutOfTermCmrcPercentage)}}
                        Out-of-term
                    </li>


                    <li>
                        <div class="title">
                            {{tenantReportDetails.annualSpendPoorDataIntegrity / 12
                            | tenantcurrency:
                            '0.0-0'}} mo.
                        </div>
                        {{getPercentage(100 - tenantReportDetails.totalDataIntegrityCmrcPercentage)}} Unmanaged Spend
                    </li>


                    <!-- <li>
                        <div class="title">
                            <b>Not Monitored</b>
                        </div>
                        Unused <br /> Subscriptions
                    </li> -->


                    <li>
                        <div class="title">{{topCategory.productCategoryName}}</div>
                        {{getPercentage(topCategory.totalCmrcPercentage)}} of Spend
                    </li>
                </ul>
            </div>
        </div>


        <div class="service-data-highlight-list card p-3">
            <h4>
                <b>Quick Links</b>
            </h4>
            <ul class="service-data-list">
                <li>
                    <a routerLink="/report-summary" class="link">
                        Run Report
                    </a>
                </li>
                <li>
                    <a routerLink="/vendors" class="link">
                        Vendor Summary
                    </a>
                </li>
                <li>
                    <a routerLink="/addresses" class="link">
                        Location Summary
                    </a>
                </li>
                <li>
                    <a routerLink="/services" class="link">
                        Service Inventory
                    </a>
                </li>
                <li>
                    <a routerLink="/manage/users" class="link">
                        User Permissions
                    </a>
                </li>
            </ul>
        </div>
    </section>
</div>
<ng-template #loading>
    <mi-loading-animation></mi-loading-animation>
</ng-template>

