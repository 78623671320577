import { Link } from 'src/shared/ng-models/Link.interface';
import { Component} from '@angular/core';

@Component({
  selector: 'mi-cost-variance-report',
  template: `
  <mi-page-header title="Cost Variance Report" [breadcrumbs]="breadCrumbs" [links]="headerLinks">
  </mi-page-header>
  <mi-metabase metaBaseDashboardId="107"></mi-metabase>
`,
})

export class CostVarianceReportComponent  {
  breadCrumbs: Link[] = [
    {
      label: "Dashboard",
      url: "/",
      internal: true
    },
    {
      label: "Cost Variance Report",
      url: "/cost-variance-report",
      internal: true
    }
  ];
  headerLinks: Link[];
}
