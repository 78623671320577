import { Component, Input, OnInit } from "@angular/core";
import { Store } from "@ngrx/store";
import * as appDataReducer from "src/shared/state/app-data/reducers";
import { ReportV2Service } from "src/libs/api2/report-v2/report-v2.service";

@Component({
  selector: 'mi-metabase',
  templateUrl: './metabase.component.html',
  styleUrls: ['./metabase.component.scss']
})

export class MetabaseComponent implements OnInit {
  @Input() metaBaseDashboardId: string;
  showLoader: boolean = true;
  tenantId: string = null;
  error: string = null;

  constructor(
    private reportV2Service: ReportV2Service,
    private store: Store<any>
  ) { }

  ngOnInit(): void {
    const tenantData = this.store.select(appDataReducer.getAppData);
    tenantData.first().subscribe((data) => {
      this.tenantId = data.tenantData.domain;
      this.loadData();
    }, (e) => (this.error = e));
  }

  loadData(): void {
    this.showLoader = true;

    this.reportV2Service.getMetabaseResponse(this.tenantId, this.metaBaseDashboardId).then((res: string) => {
      document.getElementById(`metabase-iframe-${this.metaBaseDashboardId}`).setAttribute("src", res.replace("#bordered=true", "#bordered=false").replace("&titled=true", "&titled=false"))
      document.getElementById(`metabase-iframe-${this.metaBaseDashboardId}`).addEventListener('load', () => {
        this.showLoader = false;
      });
    })
  }
}