import { Link } from 'src/shared/ng-models/Link.interface';
import { Component } from '@angular/core';

@Component({
  selector: 'mi-unalign-services',
  template: `
  <mi-page-header title="Unalign Services" [breadcrumbs]="breadCrumbs" [links]="headerLinks">
  </mi-page-header>
  <mi-align-metabase metaBaseDashboardId="106"></mi-align-metabase>
`,

})

export class UnAlignServicesComponent {
  breadCrumbs: Link[] = [
    {
      label: "Dashboard",
      url: "/",
      internal: true
    },
    {
      label: "Align Summary",
      url: "/align-summary",
      internal: true
    }
  ];
  headerLinks: Link[];
}
