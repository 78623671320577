import { Link } from 'src/shared/ng-models/Link.interface';
import { Component, OnInit} from '@angular/core';
import { TenantV2Service } from 'src/libs/api2/tenant-v2/tenant-v2.service';
import { Store } from '@ngrx/store';
import * as appDataReducer from "src/shared/state/app-data/reducers";
import { first } from 'rxjs/operators';
import { NavigationExtras, Router } from '@angular/router';

@Component({
  selector: 'mi-Tenant-reports',
  templateUrl: './report-summary.component.html',
  styleUrls: ['./report-summary.component.scss']
})

export class ReportSummaryComponent implements OnInit {

  breadCrumbs: Link[] = [
    {
      label: "Dashboard",
      url: "/",
      internal: true
    },
    {
      label: "Summary Reports",
      url: "/summary-report",
      internal: true
    }
  ];
  headerLinks: Link[];
  vendorId: string;
  showCustomReportLink: boolean = false;
  error: any;
  

  constructor(
    private tenantService: TenantV2Service,
    private store: Store<any>,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.loadVendorId();
  }

  loadVendorId(): void {
    const tenantData = this.store.select(appDataReducer.getAppData);
    tenantData.pipe(first()).subscribe(
      (data) => {
        const tenantId = data.tenantData.domain;
        const vendorName = "Bloomberg";
        if (tenantId) {
          this.tenantService.getVendorIdForInvoice(tenantId, vendorName)
            .toPromise()
            .then((res: any) => {
              this.vendorId = res[0];
              this.showCustomReportLink = !!this.vendorId;
            })
            .catch((error) => {
              this.error = error;
              console.error('Error retrieving vendor ID:', error);
            });
        } else {
          console.error('Tenant ID is undefined');
        }
      },
      (e) => {
        this.error = e;
        console.error('Error retrieving tenant data:', e);
      }
    );
  }

  navigateToUnpaidInvoiceReport(): void {
    const navigationExtras: NavigationExtras = {
      state: {
        vendorId: this.vendorId
      },
    };
    console.log('here the navigationExtras', navigationExtras);
    
    this.router.navigate(['/unpaid-invoice'], navigationExtras);
  }

}
