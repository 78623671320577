import { Link } from 'src/shared/ng-models/Link.interface';
import { Component} from '@angular/core';

@Component({
  selector: 'mi-variance-report',
  template: `
  <mi-page-header title="Variance Report" [breadcrumbs]="breadCrumbs" [links]="headerLinks">
  </mi-page-header>
  <mi-align-metabase metaBaseDashboardId="105"></mi-align-metabase>
`,
})

export class VarianceReportComponent  {
  breadCrumbs: Link[] = [
    {
      label: "Dashboard",
      url: "/",
      internal: true
    },
    {
      label: "Variance Report",
      url: "/align-summary",
      internal: true
    }
  ];
  headerLinks: Link[];
}
