<mi-page-header title="{{ 'TENANT.VENDOR_LIST_TITLE' | translate }}" [breadcrumbs]="breadCrumbs" [links]="headerLinks">
</mi-page-header>

<div class="card p-2 mt-3">
    <div class="m-2 note-info">
        <b class="title">Standard Report</b>
        <ul class="service-data-list">
            <!-- <li><button routerLink="/tenant-report" class="btn btn-sm btn-primary">Run Report</button></li> -->
            <li><a routerLink="/tenant-report">Executive Summary</a></li>
        </ul>
    </div>
    
    <div class="m-2 note-info" *ngIf="showCustomReportLink">
        <b class="title">Custom Report</b>
        <ul class="service-data-list">
            <!-- <li>View Your future custom reports here</li> -->
            <li><a (click)="navigateToUnpaidInvoiceReport()">Bloomberg Unpaid Invoice Report</a></li>
        </ul>
    </div>
</div>

<!-- <ng-container>
    <div class="report-note">
        To create custom reports, please contact your Success Manager or contact us <a
            href="mailto:customreports@miso3.com">customreports@miso3.com</a>
    </div>
</ng-container> -->