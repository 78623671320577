import {Component, OnInit, OnDestroy} from '@angular/core';
import {ActivatedRoute, Router, NavigationEnd} from '@angular/router';
import {createLogger, LOG_LEVELS} from '../../shared/logger';
import * as linkFormatter from '../../common/utils/linkFormatter';
import {BasePageComponent} from '../../shared/pages/base-page/base-page.component';
import {TranslateService} from '@ngx-translate/core';
import {Store} from '@ngrx/store';
import * as userReducer from '../../libs/user-store/state/reducers';
import * as appDataReducer from '../../shared/state/app-data/reducers';
import * as mainNavActions from '../../shared/state/main-nav/actions';
import * as mainNavReducer from '../../shared/state/main-nav/reducers';
import {animate, trigger, state, style, transition} from '@angular/animations';
import {ISecureUser} from '../../common/security/secureUser';
import {TenantSearchService} from '../../libs/api/tenantSearch/tenantSearch.service';
import {SearchResults} from '../../shared/models/SearchResults';
import {MiServiceSearchQueryParams} from '../../shared/ng-models/search/SearchQueryParams.interface';
import {Tenant} from '../../shared/models/Tenant';
import {Title} from '@angular/platform-browser';
import {FrontEndSettings, SettingsService} from "../../libs/api/settings/settings.service";
import { TenantV2Service } from '../../../src/libs/api2/tenant-v2/tenant-v2.service';
import FrontEndSettingsUtil from 'src/shared/utils/frontEndSettings.util';
import { CurrencyService } from 'src/libs/api2/currency-v2/currency.service';
import { environment } from 'src/environments/environment';
import { BillingService } from 'src/libs/api2/billing/billing.service';
import * as tenantBillingAccountActions from '../../shared/state/tenant-billing-account/actions';
import { TenantMiServiceService } from 'src/libs/api/tenant/tenantMiService.service';

const log = createLogger(LOG_LEVELS.CORE);

@Component({
  selector: 'app-root',
  templateUrl: './app-root.component.html',
  animations: [trigger('toggleNav', [
    // What happens when toggleState is true
    // state('true', style({margin: '14px 20px 48px 240px', 'min-width': '930px', 'max-width': '1320px'})),
    state('true', style({margin: '0px 20px 48px 240px','padding-bottom':'30px', 'min-width': 'initial', 'max-width': 'initial'})),
    // What happens when toggleState is false
    // state('false', style({margin: '14px 20px 48px 88px', 'min-width': '930px', 'max-width': '1472px'})),
    state('false', style({margin: '0px 20px 100px 88px','padding-bottom':'30px', 'min-width': 'initial', 'max-width': 'initial'})),
    transition('* => *', animate('300ms'))
  ])]
})

export class AppRootComponent extends BasePageComponent implements OnInit, OnDestroy {

  adminLinks: any = [
    {
      label: 'Company Management',
      path: 'manage'
    },
    {
      label: 'Currency',
      path: 'currency'
    },
    {
      label: 'Tag Manager',
      path: 'tag'
    }
  ];

  _lastCount: number = 0;
  logoSrc = '/shared/assets/images/miso3-logo.svg';
  navIsOpen: boolean = false;
  defaultLinksForAll: any = [
    /*
    {
      label: 'MISO Monitor',
      path: '/',
      additionalClasses: 'super-user'
    },
    */
    {
      label: 'Dashboard',
      path: 'dashboard'
    },
    {
      label: 'Reports',
      links: [
        {
          label: 'Report Summary',
          path: 'report-summary'
        },
        {
          label: 'Cost Variance Report',
          path: 'cost-variance-report'
        }
      ]      
    },
    {
      label: 'Vendors',
      path: 'vendors'
    },
    {
      label: 'Services',
      links: [
        {
          label: 'All Services',
          path: 'services',
          queryParams: {"active_status[]":"ACTIVE"}
        },
        {
          label: 'Data Integrity',
          path: 'data-integrity',
          queryParams: {"active_status[]":"ACTIVE"}
        },
        // { // rohit
        //   label: 'Service Groups',
        //   path: 'groups'
        // }
      ]
    },
    {
      label: 'Addresses',
      path: 'addresses'
    },
    {
      label: 'Employees',
      path: 'employees'
    },
    {
      label: 'Documents Manager',
      path: 'documents-manager'
    },
    // {
    //   label: 'Document Management',
    //   links: [
    //     {
    //       label: 'View All',
    //       path: 'associated-documents'
    //     },
    //     {
    //       label: 'Document Manger',
    //       path: 'documents-manager'
    //     },
    //     {
    //       label: 'Upload',
    //       path: 'upload-associated-documents'
    //     }
    //   ]
    // },
    /*
    {
      label: 'Reports',
      path: 'reports'
    },
    */
    /*
    {
      label: 'Administration',
      
      links: [
        {
          label: 'MISO3 Account',
          path: 'account'
        },
        {
          label: 'Company Management',
          path: 'manage'
        },
        // {
        //   label: 'Vendor Defined Fields',
        //   path: 'vendor-defined-fields'
        // },
        {
          label: 'Currency',
          path: 'currency'
        }
        // {
        //   label: 'Audit Report',
        //   path: 'audit'
        // },
      ]
      
      links: this.adminLinks
    },
    */
    // {
    //   label: 'Genesis',
    //   links: [
    //     {
    //       label: 'Normalization',
    //       path: 'normalization'
    //     }
    //   ]
    // },
    // {
    //   label: 'Genesis',
    //   path: 'bulk-uploads-v2',
    // }
  ];

  topNavStructure: any = {
    shortCuts: {
      leftLinks:[
        {
          icon: 'dashboard',
          label: 'Events',
          path: 'events',
          alertCount: 0
        },
        {
          icon: 'bell',
          label: 'Alerts',
          path: 'alerts',
          alertCount: 999
        },
      ],
      rightLinks:[
        {
          icon: 'search-left',
          label: 'Service Search',
          path: 'services'
        }
      ]
    }
  };

  navStructure: any = {
    shortCuts: {
      upperLinks: [
        {
          icon: 'dashboard',
          label: 'Dashboard',
          path: '/'
        },
        {
          icon: 'vendor-logo-placeholder',
          label: 'Vendors',
          path: 'vendors'
        },
        {
          icon: 'search-left',
          label: 'Services',
          path: 'services'
        },
        {
          icon: 'location',
          label: 'Locations',
          path: 'addresses'
        },
        {
          icon: 'phone',
          label: 'Contacts',
          path: 'employees'
        },
        {
          icon: 'download',
          label: 'Document Management',
          path: 'associated-documents'
        },
        {
          icon: 'clip-board',
          label: 'Reports',
          path: 'reports'
        },
        {
          icon: 'edit-cover',
          label: 'Normalization',
          path: 'normalization'
        },
        {
          icon: 'super-admin',
          label: 'Administration',
          path: 'manage/company-info'
        },
        {
          icon: 'file-up-arrow',
          label: 'Bulk Upload',
          path: 'bulk-uploads-v2',
        }
      ]
    },
    defaultLinks: [...this.defaultLinksForAll]
  };

  tenant: Tenant;
  error: any

  constructor(
    private store: Store<any>,
    private translate: TranslateService,
    private router: Router,
    private titleService: Title,
    private activatedRoute: ActivatedRoute,
    private settingsService: SettingsService,
    private tenantSearchService:TenantSearchService,
    private tenantV2Service: TenantV2Service,
    private tenantMiServiceService: TenantMiServiceService,
    private tenantCurrencyService: CurrencyService,
    private billingService: BillingService) {
    super(store, translate, router, titleService, activatedRoute, settingsService);
  }

  ngOnInit() {
    const fe:FrontEndSettings = environment as FrontEndSettings;
    FrontEndSettingsUtil.set(fe);
    console.log("fesettings", FrontEndSettingsUtil.get());
    super.ngOnInit();
  }

  loadDomainAndContinueInit(): void {
    const subdomain = linkFormatter.getSubdomainFromHost(window.location.host)
    this.tenantV2Service.getBusinessUnitByDomain(subdomain)
        .subscribe(
          (businessUnit) => {
            //debugger;
            if(businessUnit.role == 1 || businessUnit.role == 5) {
              this.adminLinks.push(
                {
                  label: 'Billing',
                  path: 'billing'
                }
              );
            }

            if(businessUnit.role == 1 || businessUnit.role == 6 || businessUnit.role == 5) {
              this.defaultLinksForAll.push(
                {
                  label: 'Administration',
                  links: this.adminLinks
                },
                // {
                //   label: 'Align',
                //   links: [
                //     {
                //       label: 'Teoco Vs Orion',
                //       path: 'tenants-match'
                //     },
                //     {
                //       label: 'Report',
                //       path: 'report-summary'
                //     }
                //   ]
                // },
              );
            }

            // Align Sidebar 
            if (businessUnit.id) {
              this.tenantMiServiceService
                .getAlignTenantConfig(businessUnit.id)
                .subscribe((response) => {
                  if (response.configurations.length > 0) {

                    const tenantConfig = response.configurations;
                    FrontEndSettingsUtil.setTenantConfig(tenantConfig);

                    this.defaultLinksForAll.push(
                      {
                        label: 'Align',
                        links: [
                          // {
                          //   label: 'Matching Tool',
                          //   path: 'matching-tool'
                          // },
                          {
                            label: 'Variance Report',
                            path: 'variance-report'
                          },
                          {
                            label: 'Unaligned Services',
                            path: 'unalign-services'
                          }
                        ]
                      },
                    );
                  }
                },
                  (e) => {
                    this.error = e;
                  }
                )
            }
            
            FrontEndSettingsUtil.setDomain(businessUnit.id);
            FrontEndSettingsUtil.setTenantCode(businessUnit.domain);
            if(businessUnit.tenant_currency_id){
              this.tenantCurrencyService.getTenantCurrency(businessUnit.tenant_currency_id)
              .toPromise()
              .then(data => {
                //debugger;
                FrontEndSettingsUtil.setCurrency(data);
                this.continueInit();
              });

            }
            else{
              this.continueInit();
            }
          },
          (error) => {
            
          });
    
  }

  continueInit(): void {
    super.continueInit();
    this.store.select(userReducer.getUserState)
      .takeUntil(this.destroy$)
      .subscribe((userData) => {
        if (userData && userData.secureUser) {
          this.getTenantBillingAccount();
          const nav = [...this.defaultLinksForAll];

          // Rohit do i need to remove all code as well belongs to this below links
          // if (userData.secureUser.isSuperUser()) {
          //   nav.push(
          //     {
          //       label: 'Data Connectors',
          //       path: 'data-connectors',
          //       additionalClasses: 'super-user'
          //     },
          //     {
          //       label: 'Subscriptions',
          //       path: 'subscriptions',
          //       additionalClasses: 'super-user'
          //     }
          //   );
          // }

          this.navStructure.defaultLinks = nav;
        }
      });

    this.store.select(appDataReducer.getAppData).subscribe(state => {
      this.tenant = state.tenantData;
      if (!!this.tenant && !!this.tenant.logo && !!this.tenant.logo.full_path) {
        this.logoSrc = this.tenant.logo.full_path;
      }
    });

    this.store.select(userReducer.getUserState).subscribe(
      state => {
        log('user state changed');
        if (state.verified) {
          this.fixEventLink(state.secureUser);
        }
      }
    );

    this.store.dispatch(new mainNavActions.MainNavInitializeAction(this.navStructure));

    this.store.select(mainNavReducer.getMainNav).subscribe(
      state => {
        this.navIsOpen = state.open;
      });

    this.router.events.takeUntil(this.destroy$).subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      window.scrollTo(0, 0);
    });
    /*
    this.store.select(alertReducer.getAlertsList)
      .subscribe((state) => {
        if (!state.loaded && !state.loading) {
          this.store.dispatch(new alertActions.LoadAlertsAction(null));
        }

        this.topNavStructure.shortCuts.leftLinks[1] = {
          ...this.topNavStructure.shortCuts.leftLinks[1],
          alertCount: state.alerts.length
        };

      });*/
  };

  getTenantBillingAccount(): void {
    this.billingService.getTenantBillingAccountByTenantCode(FrontEndSettingsUtil.getTenantCode()).subscribe(res => {
      console.log("getTenantBillingAccountByTenantCode", res);

      this.store.dispatch(new tenantBillingAccountActions.SetTenantBillingAccountData(res));
    });
  }

  fixEventLink(secureUser: ISecureUser) {
    if (!secureUser || secureUser.isSuperUser()) {
      // this user should not have a personalized link;
      this.topNavStructure.shortCuts.leftLinks[0] = {
        icon: 'dashboard',
        label: 'Events',
        path: 'events',
        queryParams: {}
      };
    } else {

      const myId = secureUser.getUserEmail();
      if(!myId) {
        // this user does not exist in the new microservice.
        return;
      }
      const queryParams = {
        'assigned_email_id[]': myId
      };

      this.tenantSearchService.searchTenantEvents({...queryParams, size: 0} as MiServiceSearchQueryParams)
        .first()
        .toPromise()
        .then((result: SearchResults) => {
          this._lastCount = result.total.value;
          let preferredCount: string = '' + this._lastCount;

          if (this._lastCount > 99) {
            preferredCount = '99+';
          }

          // this user is logged in and not a super user
          this.topNavStructure.shortCuts.leftLinks[0] = {
            icon: 'dashboard',
            label: 'Your events within the next 30 days',
            path: 'events',
            alertCount: preferredCount,
            queryParams: queryParams
          };

        });
    }
  }

  ngOnDestroy() {
    super.ngOnDestroy();
  }
}
