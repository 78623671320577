import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { TenantV2 } from "../types/TenantV2.interface";
import { BusinessUnitContactV2 } from "../types/BussinessUnitContactV2.interface";
import { HttpClient, HttpHeaders} from '@angular/common/http';
import { Invoice } from 'src/shared/models/unpaid-invoice';


export interface NewTenantParams {
  email: string;
  first_name: string;
  last_name: string;
  business_unit_name: string;
  business_unit_url: string;
  domain: string
}

export interface GetBussinessUnitsResponse {
  domain: string;
  is_active: any;
  created_on_utc: string;
  id: string;
  logo: string;
  name: string;
  parent_id: string;
  poc_email: string;
  poc_first_name: string;
  poc_last_name: string;
  poc_mobile_phone: string;
  poc_office_phone: string;
  role: number;
  url: string;
}



export interface SimplePaginationParams {
  count?: number;
  start?: number;
  size?: number;
}


@Injectable()
export class TenantV2Service {

  private accountHost = '_MICRO_SERVICE_/tenant';
  private tenantSummaryReportAPI = '_MICRO_SERVICE_/reports/get-summary-report';
  private lowDataIntegrityServicesAPI = '_MICRO_SERVICE_/upload/get-data-Integrity';
  private lowDataIntegrityServicesExcelAPI = '_MICRO_SERVICE_/upload/get-data-Integrity-export';
  private uploadDataIntegrityFixedDocumentAPI = '_MICRO_SERVICE_/upload/upload';
  private unpaidInvoiceAPI = '_MICRO_SERVICE_/connector/bloomberg/unpaid-invoice';
  private unpaidInvoiceDownloadAPI = '_MICRO_SERVICE_/connector/bloomberg/download/unpaid-invoice/details';


  constructor(private http: HttpClient) {

  }

  newTenant(params: any): Observable<any> {
    return this.http
      .post(`${this.accountHost}/register-tenant`, params)
      .map((response: any) => response);
  }

  getBusinessUnits(params: any = {}): Observable<GetBussinessUnitsResponse[]> {
    return this.http
      .get(`${this.accountHost}/business-units?owner=_TENANT_ID_`)
      .map((response: any) => response as GetBussinessUnitsResponse[]);
    //curl -X GET "https://api2.miso3app.com/tenant/business-units?search=test" -H "accept: application/json"
  }

  getUserBusinessUnits(params: any = {}): Observable<GetBussinessUnitsResponse[]> {
    return this.http
      .get(`${this.accountHost}/business-units`, { params })
      .map((response: any) => response as GetBussinessUnitsResponse[]);
    //curl -X GET "https://api2.miso3app.com/tenant/business-units?search=test" -H "accept: application/json"
  }

  getBusinessUnit(id: string): Observable<TenantV2> {
    const params = { id };
    return this.http
      .get(`${this.accountHost}/business-unit`, { params })
      .map((response: any) => response as TenantV2);
    //curl -X GET "https://api2.miso3app.com/tenant/business-units?search=test" -H "accept: application/json"
  }

  getBusinessUnitByDomain(domain: string): Observable<TenantV2> {
    const params = { domain };
    return this.http
      .get(`${this.accountHost}/business-unit-by-domain`, { params })
      .map((response: any) => response as TenantV2);
    //curl -X GET "https://api2.miso3app.com/tenant/business-units?search=test" -H "accept: application/json"
  }

  saveBusinessUnit(body: TenantV2): Observable<TenantV2> {
    return this.http
      .post(`${this.accountHost}/business-unit`, body)
      .map((response: any) => response as TenantV2);
  }

  getBusinessUnitContact(id: string): Observable<BusinessUnitContactV2> {
    const params = { id };
    return this.http
      .get(`${this.accountHost}/business-unit-contact`, { params })
      .map((response: any) => response as BusinessUnitContactV2);
  }

  saveBusinessContact(body: BusinessUnitContactV2): Observable<BusinessUnitContactV2> {
    return this.http
      .post(`${this.accountHost}/business-unit-contact`, body)
      .map((response: any) => response as BusinessUnitContactV2);
  }

  acceptTermsAndConditions(id: string): Observable<any> {
    return this.http
      .post(`${this.accountHost}/accept-terms-and-conditions`, id)
      .map((response: any) => response);
  }

  getBusssinessUsers(businessUnitId: string, includeall: string = ""): Observable<BusinessUnitContactV2[]> {
    const params = { id: businessUnitId, includeall };
    return this.http
      .get(`${this.accountHost}/business-unit-users`, { params })
      .map((response: any) => response as BusinessUnitContactV2[]);
  }

  downloadTemplate() {
    return this.http
      .get(`${this.accountHost}/download-template`, { responseType: 'arraybuffer' })
      .map((response: any) => response);
  }


  // "https://api2-staging.miso3app.com/reports/get-summary-report?tenantId=6f4f2171-5bce-4651-9981-a40aeddd179c"
  //
  // getMyAccess(params: SimplePaginationParams = {}): Observable<TenantV2[]> {
  //   return of([
  //     {
  //       name: "Tenant 1",
  //       active: true,
  //       parent: {
  //         name: "tenant 1",
  //         active: true
  //       },
  //       created_at: "1/12/2020",
  //       poc: {
  //         first_name: "kris",
  //         email: "kris.meister@gmail.com",
  //         phone: "989-392-3828"
  //       }
  //     },
  //     {
  //       name: "Tenant 2",
  //       active: true,
  //       created_at: "1/12/2020",
  //       parent: {
  //         name: "tenant 1",
  //         active: true
  //       },
  //       poc: {
  //         first_name: "jane",
  //         last_name: "doe",
  //         email: "kris.meister@gmail.com",
  //         phone: "989-392-3828"
  //       }
  //     }
  //   ]);
  // }

  getUserTenants(miso: boolean = true, miMiso: boolean = true): Observable<any> {
    return this.http
      .get(`${this.accountHost}/user-tenants?miso=${miso}&miMiso=${miMiso}`)
      .map((response: any) => response);
  }

  getTenantReport(tenantId: string): Observable<any> {
    return this.http
      .get(`${this.tenantSummaryReportAPI}/?tenantId=${tenantId}`)
      .map((response: any) => response);
  }

  getTenantVendorReport(tenantId: string, tenantVendorId: string): Observable<any> {
    return this.http
      .get(`${this.tenantSummaryReportAPI}/?tenantId=${tenantId}&tenantVendorId=${tenantVendorId}`)
      .map((response: any) => response);
  }

  getLowDateIntegrityServices(tenantId: string, tenantvendorid?: string, pageNumber: number = 0, pageSize: number = 10): Observable<any> {

    if (tenantvendorid) {
      return this.http
        .get(`${this.lowDataIntegrityServicesAPI}?tenantId=${tenantId}&vendorId=${tenantvendorid}&pageNumber=${pageNumber}&pageSize=${pageSize}`)

        .map((response: any) => response);
    } else {
      return this.http
        .get(`${this.lowDataIntegrityServicesAPI}?tenantId=${tenantId}&pageNumber=${pageNumber}&pageSize=${pageSize}`)
        .map((response: any) => response);
    }
  }


  getLowDateIntegrityServicesExcel(tenantId: string, knowEndDateOnly: boolean, tenantvendorid?: string): Observable<any> {
    const httpOptions = {
      responseType: 'blob' as 'json',
      observe: 'response'
    };

    if (tenantvendorid) {
      return this.http
        .get(`${this.lowDataIntegrityServicesExcelAPI}?tenantId=${tenantId}&startDate=${knowEndDateOnly}&endDate=${!knowEndDateOnly}&vendorId=${tenantvendorid}`, { observe: 'response', responseType: 'blob' as 'json' })
        .map((response: any) => {
          return response;
        });
    } else {
      return this.http
        .get(`${this.lowDataIntegrityServicesExcelAPI}?tenantId=${tenantId}&startDate=${knowEndDateOnly}&endDate=${!knowEndDateOnly}`, { observe: 'response', responseType: 'blob' as 'json' })
        .map((response: any) => {
          return response;
        });
    }

  }


  uploadDataIntegrityFile(formData: FormData, tenantId: string): Observable<any> {
    const url = this.uploadDataIntegrityFixedDocumentAPI;
    formData.append('tenantId', tenantId);
    const httpOptions = {
      headers: new HttpHeaders({
        'ignore-content-type-inject': 'ignore-content-type-inject',
      })
    };

    return this.http
      .post(url, formData, httpOptions)
      .map((response: any) => response);
  }

  getUnpaidInvoiceDates(tenantId: string, vendorId: string): Observable<Invoice[]> {
    return this.http
      .get(`${this.unpaidInvoiceAPI}/dates?tenantId=${tenantId}&vendorId=${vendorId}`)
      .map((response: any) => response);
  }

  getUnpaidInvoiceDetails(pageNumber: number = 0, pageSize: number = 10, tenantId: string, vendorId: string, invoiceDate: string): Observable<Invoice[]> {
    return this.http
      .get(`${this.unpaidInvoiceAPI}/details?invoiceDate=${invoiceDate}&page_number=${pageNumber}&page_size=${pageSize}&tenantId=${tenantId}&vendorId=${vendorId}`)
      .map((response: any) => response);
  }

  download(invoiceDate: string, tenantId: string, vendorId: string): Observable<any> {
    const url = `${this.unpaidInvoiceDownloadAPI}?invoiceDate=${invoiceDate}&tenantId=${tenantId}&vendorId=${vendorId}`;
    return this.http.get(url, { responseType: 'arraybuffer' });
  }

  getVendorIdForInvoice(tenantId: string, vendorName: string) {
    return this.http
    .get(`${this.unpaidInvoiceAPI}/vendorId?tenantId=${tenantId}&vendorName=${vendorName}`)
  }

}
